import React, { useState } from 'react';
import ScrollingInformation from '../components/ScrollingInformation';
import PremiumCard from '../components/PremiumCard';
import PremiumHero from '../components/PremiumHero';
import { Theme } from '../types/themes';
import Layout from './Layout';
import Metadata from '../components/og/Metadata';
import { ConfigData } from '../utils/config';

const Premium: React.FC = () => {
    const [theme, setTheme] = useState<Theme>({ mode: 'dark', color: '#9d52ff' });
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <Layout theme={theme} setTheme={setTheme} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
            <Metadata title="xalerium.lol - Premium" description="xalerium.lol high quality, biolinks, and image hosting." image={ConfigData.general.avatar} />
        <PremiumHero />
        <PremiumCard />
        <ScrollingInformation />
        <style>{`
                :root {
                    --accent-color: ${theme.color};
                }
                .page-container {
                    min-height: 100vh;
                    background-color: ${theme.mode === 'dark' ? '#000' : '#ffffff'};
                    color: ${theme.mode === 'dark' ? '#fff' : '#000'};
                }
                .background-decoration {
                    background: 
                        radial-gradient(circle at 10% 20%, rgba(100, 43, 115, 0.1) 0%, rgba(16, 16, 19, 0.1) 30%), radial-gradient(circle at 90% 80%, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 30%);
                }
                .premium, .stat-number {
                    color: var(--accent-color);
                }
                .btn::before, .team-card:hover {
                    border-color: var(--accent-color);
                }
                .team-card, footer {
                    background-color: ${theme.mode === 'dark' ? '#000' : '#f0f0f0'};
                    margin-top: 40px;
                }
                .stats-card:hover {
                    border: 2px solid var(--accent-color);
                }
                .information-content span:nth-child(2n+1) {
                    color: var(--accent-color);
                }
                .scrolling-information {
                    border: 2px solid var(--accent-color);
                }
                .stats-card {
                    background-color: rgb(10, 10, 10);
                }
                .stats-card p, .team-description, .footer-column a {
                    color: ${theme.mode === 'dark' ? '#b0b0b0' : '#555555'};
                }
            `}</style>
    </Layout>
    );
};

export default Premium;
