import React, { useEffect, useState } from 'react';
import { ConfigData } from '../utils/config';

interface Theme {
    mode: 'dark' | 'light';
    color: string;
}

const PremiumCard: React.FC = () => {
    const [theme] = useState<Theme>({ mode: 'dark', color: '#9d52ff' });

    useEffect(() => {
        document.documentElement.style.setProperty('--accent-color', theme.color);
    }, [theme.color]);

    return (
        <section className="pricing-section">
        <div className="pricing-container">
        <div className="free-container">
                    <h2>{ConfigData.premiumcard.free.title}</h2>
                    <p>{ConfigData.premiumcard.free.description}</p>
                    <ul>
                        <li>
                            <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round"
                                stroke-linejoin="round" className="inline-block h-4 w-4 text-primary/75" height="1em" width="1em"
                                xmlns="http://www.w3.org/2000/svg">
                                <polyline points="20 6 9 17 4 12"></polyline>
                            </svg>
                            {ConfigData.premiumcard.free.features[0]}
                        </li>
                        <li>
                            <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round"
                                stroke-linejoin="round" className="inline-block h-4 w-4 text-primary/75" height="1em" width="1em"
                                xmlns="http://www.w3.org/2000/svg">
                                <polyline points="20 6 9 17 4 12"></polyline>
                            </svg>
                            {ConfigData.premiumcard.free.features[1]}
                        </li>
                        <li>
                            <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round"
                                stroke-linejoin="round" className="inline-block h-4 w-4 text-primary/75" height="1em" width="1em"
                                xmlns="http://www.w3.org/2000/svg">
                                <polyline points="20 6 9 17 4 12"></polyline>
                            </svg>
                            {ConfigData.premiumcard.free.features[2]}
                        </li>
                        <li>
                            <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round"
                                stroke-linejoin="round" className="inline-block h-4 w-4 text-primary/75" height="1em" width="1em"
                                xmlns="http://www.w3.org/2000/svg">
                                <polyline points="20 6 9 17 4 12"></polyline>
                            </svg>
                            {ConfigData.premiumcard.free.features[3]}
                        </li>
                        <li>
                            <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round"
                                stroke-linejoin="round" className="inline-block h-4 w-4 text-primary/75" height="1em" width="1em"
                                xmlns="http://www.w3.org/2000/svg">
                                <polyline points="20 6 9 17 4 12"></polyline>
                            </svg>
                            {ConfigData.premiumcard.free.features[4]}
                        </li>
                        <li>
                            {ConfigData.premiumcard.free.features[5]}
                        </li>
                    </ul>
                    <a href={ConfigData.bio.bio} className="btn">{ConfigData.premiumcard.free.button}</a>
                </div>

                <div className="premium-container">
                    <h2>{ConfigData.premiumcard.premium.title}</h2>
                    <p>{ConfigData.premiumcard.premium.description}</p>
                    <ul>
                    <li>
                            <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round"
                                stroke-linejoin="round" className="inline-block h-4 w-4 text-primary/75" height="1em" width="1em"
                                xmlns="http://www.w3.org/2000/svg">
                                <polyline points="20 6 9 17 4 12"></polyline>
                            </svg>
                            {ConfigData.premiumcard.premium.features[0]}
                        </li>
                        <li>
                            <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round"
                                stroke-linejoin="round" className="inline-block h-4 w-4 text-primary/75" height="1em" width="1em"
                                xmlns="http://www.w3.org/2000/svg">
                                <polyline points="20 6 9 17 4 12"></polyline>
                            </svg>
                            {ConfigData.premiumcard.premium.features[1]}
                        </li>
                        <li>
                            <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round"
                                stroke-linejoin="round" className="inline-block h-4 w-4 text-primary/75" height="1em" width="1em"
                                xmlns="http://www.w3.org/2000/svg">
                                <polyline points="20 6 9 17 4 12"></polyline>
                            </svg>
                            {ConfigData.premiumcard.premium.features[2]}
                        </li>
                        <li>
                            <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round"
                                stroke-linejoin="round" className="inline-block h-4 w-4 text-primary/75" height="1em" width="1em"
                                xmlns="http://www.w3.org/2000/svg">
                                <polyline points="20 6 9 17 4 12"></polyline>
                            </svg>
                            {ConfigData.premiumcard.premium.features[3]}
                        </li>
                        <li>
                            <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round"
                                stroke-linejoin="round" className="inline-block h-4 w-4 text-primary/75" height="1em" width="1em"
                                xmlns="http://www.w3.org/2000/svg">
                                <polyline points="20 6 9 17 4 12"></polyline>
                            </svg>
                            {ConfigData.premiumcard.premium.features[4]}
                        </li>
                    </ul>
                    <a href={ConfigData.premium.upgradeurl} className="btn">{ConfigData.premiumcard.premium.button}</a>
                </div>
            </div>
        <style>
                {`
                    .pricing-section {
                        display: flex;
                        justify-content: center;
                        padding: 40px 20px;
                    }

                    .pricing-container {
                        display: flex;
                        gap: 30px;
                        max-width: 1200px;
                        width: 100%;
                        justify-content: space-between;
                        flex-wrap: wrap;
                    }

                    .free-container,
                    .premium-container {
                        flex: 1;
                        padding: 20px;
                        border-radius: 12px;
                        border: 2px solid;
                        text-align: center;
                        transition: transform 0.3s ease;
                        min-width: 280px;
                        max-width: 45%;
                    }

                    .free-container:hover,
                    .premium-container:hover {
                        transform: translateY(-5px);
                        border: 2px solid var(--accent-color);
                    }

                    .free-container {
                        background-color: rgb(10, 10, 10);
                        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
                        border: 2px solid rgb(41, 39, 42);
                    }

                    .premium-container {
                        background-color: rgb(10, 10, 10);
                        border-color: var(--accent-color);
                        box-shadow: 0px 0px 10px var(--accent-color);
                    }

                    .free-container h2,
                    .premium-container h2 {
                        margin-bottom: 10px;
                        font-size: 24px;
                        font-weight: bold;
                    }

                    .free-container p,
                    .premium-container p {
                        margin-bottom: 20px;
                    }

                    .free-container ul,
                    .premium-container ul {
                        list-style: none;
                        padding: 0;
                        margin-top: 20px;
                    }

                    .free-container li,
                    .premium-container li {
                        margin-bottom: 10px;
                        font-size: 16px;
                        display: flex;
                        align-items: center;
                    }

                    .free-container li svg,
                    .premium-container li svg {
                        margin-right: 8px;
                        color: var(--accent-color);
                    }

                    @media (max-width: 768px) {
                        .pricing-container {
                            flex-direction: column;
                            gap: 20px;
                        }

                        .free-container,
                        .premium-container {
                            max-width: 100%;
                            padding: 15px;
                        }

                        .free-container h2,
                        .premium-container h2 {
                            font-size: 20px;
                        }

                        .free-container li,
                        .premium-container li {
                            font-size: 14px;
                        }
                    }

                    @media (max-width: 480px) {
                        .pricing-section {
                            padding: 20px 10px;
                        }

                        .free-container h2,
                        .premium-container h2 {
                            font-size: 18px;
                        }

                        .btn {
                            padding: 8px 16px;
                        }
                    }
                `}
            </style>
    </section>
    );
};

export default PremiumCard;
