export const Links = {
    bio: 'https://bio.xalerium.hu/dash',
    home: '/',
    uploader: 'https://pics.xalerium.hu/dash',
    discord: 'https://discord.xalerium.hu',
    shortie: 'https://s.xalerium.hu',
    // xalerfile: 'https://xalerfile.xalerium.hu',
    // style1: 'https://api.xalerium.hu/v2/_next/static/media/xaleriumgg/main/css/cd67Lqwd.css',
    // style2: 'https://api.xalerium.hu/v2/_next/static/media/xaleriumgg/main/css/cd77Lqwd.css',
    // style3: 'https://api.xalerium.hu/v2/_next/static/media/xaleriumgg/main/css/cd87Lqwd.css',
    // style4: 'https://api.xalerium.hu/v2/_next/static/media/xaleriumgg/main/css/cd97Lqwd.css',
    // catgif: 'https://api.xalerium.hu/v2/_next/static/media/xaleriumgg/main/images/66b1eb08d9b10.gif'
  };
  
export default Links;