import React from "react";

const ScrollingInformation: React.FC = () => {
    return (
        <div className="scrolling-information">
            <div className="information-content">
                <span>PREMIUM FEATURES</span>
                <span>CUSTOM DOMAINS</span>
                <span>EFFECTS</span>
                <span>IMAGE HOST</span>
                <span>BIOLINK</span>
            </div>
            <style>{`
                .scrolling-information {
                    width: 100%;
                    overflow: hidden;
                    background-color: #000;
                    padding: 20px 0;
                    margin-bottom: 20px;
                    border: 2px solid hsla(275.6, 100%, 60%, 0.15);
                    border-radius: 10px;
                    display: flex;
                    justify-content: center;
                    margin-top: 50px;
                }
                .information-content {
                    display: flex;
                    white-space: nowrap;
                    animation: scroll 30s linear infinite;
                }
                .information-content span {
                    display: inline-block;
                    padding: 0 30px;
                    color: #fff;
                    font-weight: bold;
                    text-transform: uppercase;
                    /* font-size: 1.1em; */
                }
                .animation-pause:hover {
                    animation-play-state:paused;
                }
                .information-content span:nth-child(odd) {
                    color: #9d52ff;
                }
                @keyframes scroll {
                    0% {
                        transform: translateX(100%);
                    }
                    100% {
                        transform: translateX(-100%);
                    }
                }
                /* Adding animation control for smoother experience */
                .information-content {
                    animation: scroll 30s linear infinite;
                }
            `}</style>
        </div>
    );
};

export default ScrollingInformation;
