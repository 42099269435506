import React, { useEffect, useState } from "react";
import { ConfigData } from '../utils/config';

const SERVER_INVITE = 'NAPtwwv4gE';

const DiscordServer: React.FC = () => {
    const [onlineCount, setOnlineCount] = useState<number>(0);
    const [totalCount, setTotalCount] = useState<number>(0);

    const fetchDiscordMemberCount = async () => {
        try {
            const response = await fetch(`https://discord.com/api/v9/invites/${SERVER_INVITE}?with_counts=true`);
            const data = await response.json();
            
            const online = data.approximate_presence_count;
            const total = data.approximate_member_count;
            
            setOnlineCount(online);
            setTotalCount(total);
        } catch (error) {
            console.error('Error fetching Discord member count:', error);
        }
    };

    useEffect(() => {
        fetchDiscordMemberCount();
    }, []);

    return (
        <section className="discord-ad-container fade-in aos-init">
            <div className="discord-ad">
                <div className="discord-content">
                    <h2>{ConfigData.homepage.discordtitle}</h2>
                    <p>{ConfigData.homepage.discorddesc}</p>
                    <a href={ConfigData.general.discord} className="discord-button">{ConfigData.homepage.discordbutton}</a>
                    <div className="member-count">
                        <span>{totalCount !== undefined ? totalCount.toLocaleString() : '0'}</span> {ConfigData.homepage.discordonline}
                    </div>
                </div>
                <div className="discord-image">
                    <img src="https://api.xalerium.hu/v2/_next/static/media/xaleriumgg/main/images/discordlogo.png" alt="Discord" />
                </div>
            </div>
            <style>{`
                    
    .discord-ad-container {
      margin-top: 100px;
      padding: 60px 20px;
    }
    .discord-ad {
      max-width: 900px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: linear-gradient(to right, #41206d38, #0f091c);
      border-radius: 20px;
      padding: 40px;
    }
    .discord-content {
      flex: 1;
      padding-right: 40px;
    }
    .discord-content h2 {
      font-size: 26px;
      margin-bottom: 20px;
      color: #ffffff;
    }
    .discord-content p {
      font-size: 14px;
      color: #a0a0a0;
      margin-bottom: 30px;
    }
    .discord-button {
      display: inline-block;
      padding: 12px 41px;
      background-color: #0000005e;
      border: 2px solid rgb(41, 39, 42);
      color: #ffffff;
      text-decoration: none;
      border-radius: 8px;
      font-size: 18px;
      transition: transform 0.3s ease, border-color 0.3s ease;
    }
    .discord-button:hover {
      border-color: var(--accent-color);
      transform: translateY(-5px);
    }
    .discord-image {
      flex: 0 0 auto;
    }
    .discord-image img {
      width: 200px;
      height: auto;
    }
    .member-count {
      margin-top: 15px;
      margin-left: 5px;
      font-size: 10px;
      color: #838383
    }

    @media (max-width: 768px) {
      .discord-ad {
        flex-direction: column;
        text-align: center;
      }

      .discord-content {
        padding-right: 0;
        margin-bottom: 30px;
      }

      .discord-image {
        margin-top: 20px;
      }
    }
            
            `}</style>
        </section>
    );
};

export default DiscordServer;
