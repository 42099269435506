export const ConfigData = {
    bio: {
        bio: 'https://bio.xalerium.hu/dash'
    },
    pics: {
        uploader: 'https://pics.xalerium.hu/dash'
    },
    general: {
        name: 'Xalerium',
        namelol: 'xalerium.lol',
        description: 'High quality, and easy to use services for your bio, image hosting, and more.',
        avatar: 'https://api.xalerium.hu/v2/_next/static/media/xalerium/images/66b79509d2c1b.png',
        discord: 'https://discord.xalerium.hu',
        home: '/',
        premium: '/premium',
        dashboard: 'https://bio.xalerium.hu/dash',
        dashboardtext: 'Dashboard',
        discordtext: 'Discord',
        hometext: 'Home',
        premiumtext: 'Premium',
        realinvite: 'https://discord.gg/NAPtwwv4gE',
        invitecode: 'NAPtwwv4gE',
        changetheme: 'Theme',
        theme: 'Theme',
        emailurl: 'mailto:xalerium@firemail.cc',
        email: 'xalerium@firemail.cc'
    },
    homepage: {
        imgdesc: 'Upload and share your images seamlessly with our image host service.',
        biodesc: 'Link all your socials in one simple place using our bio link service.',
        ourteam: 'Our Team',
        stats: 'Stats',
        discordtitle: 'XALERIUM DISCORD COMMUNITY',
        discorddesc: 'Be a part of our discord community and get the news, support, and more.',
        discordbutton: 'Join',
        discordonline: 'Members',
        biotitle: 'xalerium.bio',
        imgtitle: 'xalerium.pics',
        imgstats: {
            uploads: 'Uploads',
            domains: 'Domains',
            storage: 'Used Storage',
            shortened: 'Shortened URLs',
            users: 'Users'
        },
        biostats: {
            views: 'Views',
            biolinks: 'Biolinks',
            users: 'Users',
            premium: 'Premium Users'
        },
    },
    premium: {
        text: 'Premium',
        description: 'Buy Premium to unlock advanced features.',
        buttontext: 'Buy Premium',
        upgradeurl: 'https://discord.xalerium.hu/'
    },
    premiumcard: {
        free: {
            title: 'Free',
            description: 'Get started with basic features for free.',
            features: [
                'Biolink',
                'Border Effects',
                'Avatar Decorations',
                'Fonts',
                'Background Audio & Video',
                'and more...'
            ],
            button: 'Get Started'
        },
        premium: {
            title: 'Premium',
            description: 'Unlock advanced features with a one-time payment of $5.',
            features: [
                'Discord Role',
                'Premium Badge',
                'Premium Fonts',
                'Custom Domain',
                'Image Host'
            ],
            button: 'Upgrade'
        }
    },
    faq: {
        title: 'Frequently Asked Questions.',
        description: 'Here are some of the most frequently asked questions about our services.',
        faq: [
            {
                question: "How do I get an invite?",
                answer: "You can obtain an invite by asking a friend who has invite code or occasionally through our Discord server."
            },
            {
                question: "What is the file size limit?",
                answer: "The file size limit is 75MB for free users. However, this limit may be increased in the future."
            },
            {
                question: "How do I get a custom domain?",
                answer: "You can add a domain by using \"/adddomain\" command in our Discord server."
            },
            {
                question: "How long are my files stored?",
                answer: "Files are stored forever unless you delete them or our staff team delete them."
            },
            {
                question: "How do i get access to image host?",
                answer: "You can get access by buying a premium plan."
            }
        ]
    },
    privacy: {
        title: 'Privacy Policy',
        policy: [
            {
                title: '1. Personal Information',
                description: 'We are not responsible for the personal information that you provide to us. We do not collect, store, or use any personal information from you. We only use your personal information to provide you with our services and to communicate with you.'
            },
            {
                title: '2. Data Collection',
                description: 'We do not sell or share your personal data with third-party organizations for marketing purposes.'
            },
            {
                title: '3. Cookies',
                description: 'We use cookies to improve your experience on our website. Cookies are small text files that are stored on your device when you visit our website. They help us to remember your preferences and provide you with a more personalized experience. We do not use cookies to track your browsing activity or to collect personal information.'
            },
            {
                title: '4. Data Security',
                description: 'We implement reasonable security measures to protect data we collect. However, no transmission of data over the internet is totally secure. While we take reasonable measures to protect your data, we cannot guarantee the absolute security of your data.'
            },
            {
                title: '5. Account Termination and Data Deletion',
                description: 'In the event of the termination of your account, We will remove all personal information, Users may request deletion of an account and data by directly contacting us.'
            },
            {
                title: '5. Changes to Privacy Policy',
                description: 'We reserve the right to modify the Privacy Policy at any time. If we make any changes, we will notify you by making announcement on our website.'
            },
            {
                title: '6. Eligibility',
                description: 'You must be at least 13 years old to use our services or older We will never collect data from children under 13 If you are a parent, please contact us at '
            },
            {
                title: '7. Contact Us',
                description: 'If you have any questions or concerns about these privacy policies, please contact us at '
            }
        ]
    },
    tos: {
        title: 'Terms of Service',
        policy: [
            {
                title: '1. Acceptance of Terms',
                description: 'By accessing and using our services, you agree to comply with these Terms of Service, all applicable laws, and regulations. If you do not agree with any part of these terms, you are prohibited from using our services.'
            },
            {
                title: '2. Acceptable Use',
                description: 'You agree that you will use Xalerium services responsibly and in a manner consistent with the laws of the jurisdiction where you reside or access the service. The following uses are explicitly prohibited:'
            },
            {
                title: '2.1 You must not spam or otherwise send deceptive content.',
                description: 'You must not perform any activity that endangers your account security.'
            },
            {
                title: '2.2 You must not perform any activity that endangers your account security.',
                description: 'Sending malware, phishing, and any other form of cyber attack using our services.'
            },
            {
                title: '3. Limitation of Liability',
                description: 'In no event shall Xalerium, its affiliates and their respective officers, directors, employees and agents to the fullest extent permissible under applicable law, be liable for any damages (including, without limitation, damages for loss of data, or due to business interruption) arising out of the use or inability to use our services.'
            },
            {
                title: '4. Termination',
                description: 'We reserve the right to terminate or suspend your account at any time, without or with notice, if you breach these Terms of Service or have engaged in illegal or otherwise harmful conduct through the use of our Website.'
            },
            {
                title: '5. Changes to Terms',
                description: 'We reserve the right to modify the Terms of Service at any time. If we make any changes, we will notify you by making announcement on our website.'
            },
            {
                title: '6. Contact Us',
                description: 'If you have any questions or concerns about these terms, please contact us at '
            }
        ]
    },
    partners: {
        title: 'Partners',
        partners: [
            {
                name: 'X',
                description: 'X is a social media platform that connects people with their passions and interests. X is a place where people can share their ideas, connect with like-minded individuals, and discover new opportunities. X is a community of like-minded individuals who share their passions, interests, and experiences with each other. X is a place where people can connect, share, and grow together.',
                url: 'https://x.com/naohcatt'
            },
            {
                name: 'Instagram',
                description: 'Instagram is a social media platform that connects people with their passions and interests. Instagram is a place where people can share their ideas, connect with like-minded individuals, and discover new opportunities. Instagram is a community of like-minded individuals who share their passions, interests, and experiences with each other. Instagram is a place where people can connect, share, and grow together.',
                url: 'https://www.instagram.com/naohcatt/'
            },
            {
                name: 'TikTok',
                description: 'TikTok is a social media platform that connects people with their passions and interests. TikTok is a place where people can share their ideas, connect with like-minded individuals, and discover new opportunities. TikTok is a community of like-minded individuals who share their passions, interests, and experiences with each other. TikTok is a place where people can connect, share, and grow together.',
                url: 'https://www.tiktok.com/@naohcatt'
            }
        ]
    },
    footer: {
        copyright: '2023-2024 Xalerium. All Rights Reserved.',
        links: [
            {
                name: 'Links',
                links: [
                    {
                        name: 'Home',
                        url: '/'
                    },
                    {
                        name: 'Premium',
                        url: '/premium'
                    },
                    {
                        name: 'Partners',
                        url: '/partners'
                    },
                    {
                        name: 'Discord',
                        url: 'https://discord.xalerium.hu'
                    }
                ]
            },
            {
                name: 'Socials',
                links: [
                    {
                        name: 'Discord',
                        url: 'https://discord.xalerium.hu'
                    },
                    {
                        name: 'X',
                        url: 'https://x.com/naohcatt'
                    },
                    {
                        name: 'Instagram',
                        url: '#'
                    },
                    {
                        name: 'TikTok',
                        url: '#'
                    }
                ]
            },
            {
                name: 'Legal',
                links: [
                    {
                        name: 'Terms of Service',
                        url: '/tos'
                    },
                    {
                        name: 'Privacy Policy',
                        url: '/privacy'
                    },
                ]
            }
        ]
    }
};