import React, { useState } from 'react';
import { HelpCircle } from 'lucide-react';
import { ConfigData } from '../../utils/config';

const FAQ: React.FC = () => {
  const [theme, setTheme] = useState<{ mode: 'dark' | 'light', color: string }>({ mode: 'dark', color: '#9d52ff' });

  return (
    <div className="faq-container">
      <h1 className="faq-title">{ConfigData.faq.title}</h1>
      <div className="faq-grid">
        {ConfigData.faq.faq.map((item, index) => (
          <div key={index} className="faq-item">
            <div className="faq-icon-bg">
            <svg height="1.25em" width="1.25em" fill="rgb(121, 28, 185)" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512">
                <path
                    d="M80 160c0-35.3 28.7-64 64-64h32c35.3 0 64 28.7 64 64v3.6c0 21.8-11.1 42.1-29.4 53.8l-42.2 27.1c-25.2 16.2-40.4 44.1-40.4 74V320c0 17.7 14.3 32 32 32s32-14.3 32-32v-1.4c0-8.2 4.2-15.8 11-20.2l42.2-27.1c36.6-23.6 58.8-64.1 58.8-107.7V160c0-70.7-57.3-128-128-128H144C73.3 32 16 89.3 16 160c0 17.7 14.3 32 32 32s32-14.3 32-32zm80 320a40 40 0 1 0 0-80 40 40 0 1 0 0 80z">
                </path>
            </svg>
            </div>
            <h2 className="faq-question">{item.question}</h2>
            <p className="faq-answer">{item.answer}</p>
          </div>
        ))}
      </div>
      <style>{`

.faq-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
    color: #fff;
  }
  .faq-title {
    font-size: 2.5rem;
    color: #fff;
    margin-bottom: 10px;
  }
  .faq-description {
    color: #888;
    margin-bottom: 40px;
  }
  .faq-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
  }
  .faq-item {
    border-radius: 10px;
    padding: 14px;
    transition: all 0.3s ease;
  }
  .faq-icon {
    color: #9d52ff;
    margin-bottom: 15px;
  }
  .faq-icon-bg {
    background-color: #631cb91a;
    border-radius: .25rem;
    width: 47px;
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    /* margin-left: 147px; */
    margin: 10px auto;
    border: 1px solid rgb(106, 28, 185);
  }
  .faq-question {
    font-size: 1.2rem;
    color: #fff;
    margin-bottom: 10px;
  }
  .faq-answer {
    color: #ccc;
    font-size: 0.9rem;
  }
  @media (max-width: 768px) {
    .faq-grid {
      grid-template-columns: 1fr;
    }
    
    .faq-icon-bg {
      margin-left: 131px;
    }
  }

      `}</style>
    </div>
  );
};

export default FAQ;