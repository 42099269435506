import React, { useEffect, useState } from 'react';
import Stats from '../components/Stats';
import { ConfigData } from '../utils/config';
import ThemeChangerModal from '../components/ThemeChangerModal';
import FooterCard from '../components/index/Footer';
import Navbar from '../components/Navbar';
import Hero from '../components/index/Hero';
import DiscordServer from '../components/DiscordServer';
import FAQ from '../components/index/faq';
import Metadata from '../components/og/Metadata';

interface TeamMember {
    name: string;
    role: string;
    biourl: string;
    description: string;
    avatar: string;
}

interface Theme {
    mode: 'dark' | 'light';
    color: string;
}

const Main: React.FC = () => {
    const [team, setTeam] = useState<TeamMember[]>([]);
    const [theme, setTheme] = useState<Theme>({ mode: 'dark', color: '#9d52ff' });
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleThemeChange = (newTheme: any) => {
        setTheme(newTheme);
        setIsModalOpen(false);
    };

    useEffect(() => {
        const fetchTeamData = async () => {
            try {
                const response = await fetch('https://api.xalerium.hu/view/main/data.php');
                const data = await response.json();
                setTeam(data);
            } catch (error) {
                console.error('Error fetching team data:', error);
            }
        };

        fetchTeamData();
    }, []);

    useEffect(() => {
        document.documentElement.style.setProperty('--accent-color', theme.color);
    }, [theme.color]);

    return (
        <div className={`page-container ${theme.mode}`}>
            <Navbar theme={theme} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} handleThemeChange={handleThemeChange} />
            <Metadata title="xalerium.lol" description="xalerium.lol high quality, biolinks, and image hosting." image={ConfigData.general.avatar} />
            <main>
                {isModalOpen && (
                    <ThemeChangerModal 
                        currentTheme={theme} 
                        onThemeChange={handleThemeChange} 
                        onClose={() => setIsModalOpen(false)} 
                    />
                )}
                <Hero />
                <Stats />
                {/* <img src="http://localhost:3000/api/view/bio/og?username=naoh" alt="naoh" /> */}
                <section className="team-section">
                    <h2 className="section-title">{ConfigData.homepage.ourteam}</h2>
                    <div className="team-grid">
                        {team.map((member, index) => (
                            <a href={member.biourl} target="_blank">
                                <div className="team-card" key={index} style={{ cursor: 'pointer' }}>
                                <img src={member.avatar} alt={member.name} className="team-avatar" />
                                <div className="team-info">
                                    <h3 className="team-name">{member.name}</h3>
                                    <p className="team-description">{member.description}</p>
                                </div>
                            </div>
                        </a>
                        ))}
                    </div>
                </section>
                <FAQ />
                <DiscordServer />
            </main>
            <FooterCard />
            <div className="background-decoration"></div>
            <style>{`
                :root {
                    --accent-color: ${theme.color};
                }
                .page-container {
                    min-height: 100vh;
                    background-color: ${theme.mode === 'dark' ? '#000' : '#ffffff'};
                    color: ${theme.mode === 'dark' ? '#fff' : '#000'};
                }
                .background-decoration {
                    background: 
                        radial-gradient(circle at 10% 20%, rgba(100, 43, 115, 0.1) 0%, rgba(16, 16, 19, 0.1) 30%), radial-gradient(circle at 90% 80%, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 30%);
                }
                .premium, .stat-number {
                    color: var(--accent-color);
                }
                .btn::before, .team-card:hover {
                    border-color: var(--accent-color);
                }
                .team-card, footer {
                    background-color: ${theme.mode === 'dark' ? '#000' : '#f0f0f0'};
                    margin-top: 40px;
                }
                .stats-card:hover {
                    border: 2px solid var(--accent-color);
                }
                .information-content span:nth-child(2n+1) {
                    color: var(--accent-color);
                }
                .scrolling-information {
                    border: 2px solid var(--accent-color);
                }
                .stats-card {
                    background-color: rgba(2, 2, 2, 0.33);
                }
                .stats-card p, .team-description, .footer-column a {
                    color: ${theme.mode === 'dark' ? '#b0b0b0' : '#555555'};
                }
            `}</style>
        </div>
    );
};

export default Main;
