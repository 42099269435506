import React from 'react';
import Navbar from '../components/Navbar';
import ThemeChangerModal from '../components/ThemeChangerModal';
import { Theme } from '../types/themes';
import FooterCard from '../components/index/Footer';

interface LayoutProps {
    theme: Theme;
    setTheme: (newTheme: Theme) => void;
    isModalOpen: boolean;
    setIsModalOpen: (open: boolean) => void;
    children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ theme, setTheme, isModalOpen, setIsModalOpen, children }) => {
    const handleThemeChange = (newTheme: Theme) => {
        setTheme(newTheme);
        setIsModalOpen(false);
    };

    return (
        <div className={`page-container ${theme.mode}`} style={{ '--accent-color': theme.color } as React.CSSProperties}>
            <Navbar theme={theme} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} handleThemeChange={handleThemeChange} />
            <main>
                {isModalOpen && (
                    <ThemeChangerModal
                        currentTheme={theme}
                        onThemeChange={handleThemeChange}
                        onClose={() => setIsModalOpen(false)}
                    />
                )}
                {children}
            </main>
            <FooterCard />
            <div className="background-decoration"></div>
            <style>{`
                :root {
                    --accent-color: ${theme.color};
                }
                .page-container {
                    min-height: 100vh;
                    background-color: ${theme.mode === 'dark' ? '#000' : '#ffffff'};
                    color: ${theme.mode === 'dark' ? '#fff' : '#000'};
                }
                .premium, .stat-number {
                    color: var(--accent-color);
                }
                .btn::before, .team-card:hover {
                    border-color: var(--accent-color);
                }
                .team-card, footer {
                    background-color: ${theme.mode === 'dark' ? '#000' : '#f0f0f0'};
                }
                .stats-card:hover {
                    border: 2px solid var(--accent-color);
                }
                .footer-column a {
                    color: ${theme.mode === 'dark' ? '#b0b0b0' : '#555555'};
                }
            `}</style>
        </div>
    );
};


export default Layout;
