import React from 'react';
import { ConfigData } from '../../utils/config';
import StatusIndicator from '../StatusIndicator';
import { useLocation } from 'react-router-dom';

const Footer: React.FC = () => {
    const location = useLocation();
    return (
        <footer>
        <div className="footer-content">
        <StatusIndicator urls={[
            'https://bio.xalerium.hu', 
            'https://pics.xalerium.hu', 
            'https://api.xalerium.hu', 
            'https://catmeow.xalerium.hu']} />
            <div className="footer-logo">
                <img src={ConfigData.general.avatar} alt={ConfigData.general.name} className="footer-logo-img" />
            </div>
            <div className="footer-links">
                <div className="footer-column">
                    <h4>{ConfigData.footer.links[0].name}</h4>
                    <a 
                    className={`button ${location.pathname === '/' ? 'premium' : ''}`} 
                    href={ConfigData.footer.links[0].links[0].url}>{ConfigData.footer.links[0].links[0].name}</a>
                    <a 
                    className={`button ${location.pathname === '/premium' ? 'premium' : ''}`} 
                    href={ConfigData.footer.links[0].links[1].url}>{ConfigData.footer.links[0].links[1].name}</a>
                </div>
                <div className="footer-column">
                    <h4>{ConfigData.footer.links[1].name}</h4>
                    <a 
                    className="button"
                    href={ConfigData.footer.links[1].links[0].url}>{ConfigData.footer.links[1].links[0].name}</a>
                    <a 
                    className="button"
                    href={ConfigData.footer.links[1].links[1].url}>{ConfigData.footer.links[1].links[1].name}</a>
                    <a 
                    className="button"
                    href={ConfigData.footer.links[1].links[2].url}>{ConfigData.footer.links[1].links[2].name}</a>
                    <a 
                    className="button"
                    href={ConfigData.footer.links[1].links[3].url}>{ConfigData.footer.links[1].links[3].name}</a>
                </div>
                <div className="footer-column">
                    <h4>{ConfigData.footer.links[2].name}</h4>
                    <a 
                    className={`button ${location.pathname === '/tos' ? 'premium' : ''}`} 
                    href={ConfigData.footer.links[2].links[0].url}>{ConfigData.footer.links[2].links[0].name}</a>
                    <a 
                    className={`button ${location.pathname === '/privacy' ? 'premium' : ''}`} 
                    href={ConfigData.footer.links[2].links[1].url}>{ConfigData.footer.links[2].links[1].name}</a>
                </div>
            </div>
        </div>
        <div className="footer-bottom">
            <p>&copy; {ConfigData.footer.copyright}</p>
        </div>
    </footer>
    );
}

export default Footer;