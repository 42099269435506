import React from "react";
import { ConfigData } from "../utils/config";

const PremiumHero: React.FC = () => {
    return (
        <section className="hero">
                    <h1>{ConfigData.premium.text}</h1>
                    <p>{ConfigData.premium.description}</p>
                <a href={ConfigData.general.discord}>
                <button className="btn" style={{ cursor: 'pointer' }}>{ConfigData.premium.buttontext}
                    </button>
                </a>
                </section> 
    );
}

export default PremiumHero;