import React, { useState, useEffect } from 'react';

interface StatusIndicatorProps {
  urls: string[];
}

const StatusIndicator: React.FC<StatusIndicatorProps> = ({ urls }) => {
  const [status, setStatus] = useState<'operational' | 'down'>('operational');

  useEffect(() => {
    const checkStatus = async () => {
      try {
        const results = await Promise.all(
          urls.map(url => fetch(url, { method: 'HEAD', mode: 'no-cors' }))
        );
        const allOperational = results.every(res => res.status === 0 || res.ok);
        setStatus(allOperational ? 'operational' : 'down');
      } catch (error) {
        console.error('Error checking status:', error);
        setStatus('down');
      }
    };

    checkStatus();
    const interval = setInterval(checkStatus, 60000);

    return () => clearInterval(interval);
  }, [urls]);

  return (
    <div className={`status-indicator ${status}`}>
      <span className="status-dot"></span>
      <span className="status-text">
        {status === 'operational' ? 'Systems operational' : 'Some services are down'}
      </span>
      <style>{`
        .status-indicator {
          display: flex;
          align-items: center;
          padding: 5px 10px;
          border-radius: 15px;
          background-color: hsla(0, 0%, 100%, 0.03);
          border: 1px solid hsla(0,0%,100%,.05);
          font-size: 14px;
        }
        .status-dot {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          margin-right: 8px;
        }
        .operational .status-dot {
          background-color: #2ecc40;
          animation: pulse-green 2s infinite;
        }
        .down .status-dot {
          background-color: #ff4136;
        }
        .status-text {
          color: #fff;
        }
        @keyframes pulse-green {
          0% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(46, 204, 64, 0.7);
          }
          70% {
            transform: scale(1);
            box-shadow: 0 0 0 10px rgba(46, 204, 64, 0);
          }
          100% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(46, 204, 64, 0);
          }
        }
        @keyframes pulse-red {
          0% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(255, 65, 54, 0.7);
          }
          70% {
            transform: scale(1);
            box-shadow: 0 0 0 10px rgba(255, 65, 54, 0);
          }
          100% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(255, 65, 54, 0);
          }
        }
      `}</style>
    </div>
  );
};

export default StatusIndicator;