import React, { useEffect, useState } from 'react';
import { ConfigData } from '../utils/config';
import Links from '../utils/urls';

const Stats: React.FC = () => {
    const [bioStats, setBioStats] = useState<any>(null);
    const [imageHostStats, setImageHostStats] = useState<any>(null);
    const [loading, setLoading] = useState(true);

    const fetchBioStats = async () => {
        try {
            const response = await fetch('https://api.xalerium.hu/view/biolink/stats.php');
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setBioStats(data);
        } catch (error) {
            console.error("Error fetching bio stats:", error);
            setBioStats({ total_views: 0, total_biolinks: 0, total_users: 0, total_premium_users: 0 });
        }
    };

    const fetchImageHostStats = async () => {
        try {
            const response = await fetch('https://api.xalerium.hu/view/imghost/stats.php');
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setImageHostStats(data);
        } catch (error) {
            console.error("Error fetching image host stats:", error);
            setImageHostStats({ total_uploads: 0, total_domains: 0, total_storage_used: 0, total_storage_size_unit: 0, total_shortened_urls: 0 });
        }
    };
    
    useEffect(() => {
        const fetchData = async () => {
            await Promise.all([fetchBioStats(), fetchImageHostStats()]);
            setLoading(false);
        };

        fetchData();
    }, []);

    if (loading) {
        return (
            <div className="stats-container">
                <a href={Links.uploader}>
                    <div className="stats-card image-hosting-stats">
                        <h2>...</h2>
                        <p>...</p>
                        <div className="stats">
                            <div className="stat">
                                <span className="stat-number">...</span>
                                <span className="stat-label">{ConfigData.homepage.imgstats.uploads}</span>
                            </div>
                            <div className="stat">
                                <span className="stat-number">...</span>
                                <span className="stat-label">{ConfigData.homepage.imgstats.domains}</span>
                            </div>
                            <div className="stat">
                                <span className="stat-number">...</span>
                                <span className="stat-label">{ConfigData.homepage.imgstats.storage}</span>
                            </div>
                        </div>
                    </div>
                </a>
                <a href={Links.bio}>
                    <div className="stats-card bio-stats">
                        <h2>...</h2>
                        <p>...</p>
                        <div className="stats">
                            <div className="stat">
                                <span className="stat-number">...</span>
                                <span className="stat-label">{ConfigData.homepage.biostats.views}</span>
                            </div>
                            <div className="stat">
                                <span className="stat-number">...</span>
                                <span className="stat-label">{ConfigData.homepage.biostats.biolinks}</span>
                            </div>
                            <div className="stat">
                                <span className="stat-number">...</span>
                                <span className="stat-label">{ConfigData.homepage.biostats.users}</span>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        );
    }
    

    return (
        <div className="stats-container">
        <a href={Links.uploader}>
            <div className="stats-card image-hosting-stats">
                <h2>{ConfigData.homepage.imgtitle}</h2>
                <p>{ConfigData.homepage.imgdesc}</p>
                <div className="stats">
                    <div className="stat">
                        <span className="stat-number">{imageHostStats?.total_uploads || 0}</span>
                        <span className="stat-label"><strong>{ConfigData.homepage.imgstats.uploads}</strong></span>
                    </div>
                    <div className="stat">
                        <span className="stat-number">{imageHostStats?.total_domains || 0}</span>
                        <span className="stat-label"><strong>{ConfigData.homepage.imgstats.domains}</strong></span>
                    </div>
                    <div className="stat">
                        <span className="stat-number">{imageHostStats?.total_storage_used + imageHostStats?.total_storage_size_unit || 0}</span>
                        <span className="stat-label"><strong>{ConfigData.homepage.imgstats.storage}</strong></span>
                    </div>
                    <div className="stat">
                        <span className="stat-number">{imageHostStats?.total_shortened_urls || 0}</span>
                        <span className="stat-label"><strong>{ConfigData.homepage.imgstats.shortened}</strong></span>
                    </div>
                </div>
            </div>
        </a>
        <a href={Links.bio}>
            <div className="stats-card bio-stats">
                <h2>{ConfigData.homepage.biotitle}</h2>
                <p>{ConfigData.homepage.biodesc}</p>
                <div className="stats">
                    <div className="stat">
                        <span className="stat-number">{bioStats?.total_views || 0}</span>
                        <span className="stat-label"><strong>{ConfigData.homepage.biostats.views}</strong></span>
                    </div>
                    <div className="stat">
                        <span className="stat-number">{bioStats?.total_biolinks || 0}</span>
                        <span className="stat-label"><strong>{ConfigData.homepage.biostats.biolinks}</strong></span>
                    </div>
                    <div className="stat">
                        <span className="stat-number">{bioStats?.total_users || 0}</span>
                        <span className="stat-label"><strong>{ConfigData.homepage.biostats.users}</strong></span>
                    </div>
                    <div className="stat">
                        <span className="stat-number">{bioStats?.total_premium_users || 0}</span>
                        <span className="stat-label"><strong>{ConfigData.homepage.biostats.premium}</strong></span>
                    </div>
                </div>
            </div>
        </a>
    </div>
    );
};

export default Stats;
