import React from "react";
import { ConfigData } from "../../utils/config";

const Hero: React.FC = () => {
    return (
        <section className="hero">
            <h1>{ConfigData.general.name}</h1>
            <p>{ConfigData.general.description}</p>
        </section>
    );
}

export default Hero;